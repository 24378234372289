import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import s from './gallery.scss'

const GALLERY_QUERY = graphql`
	query GalleryQuery {
		prismicHomePageBodyGallery {
			items {
				image {
					localFile {
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`

const Gallery = () => {
	const {
		prismicHomePageBodyGallery: { items },
	} = useStaticQuery(GALLERY_QUERY)

	return (
		<section className={s.gallery}>
			<div className={s.gallery__wrapper}>
				{items.map((item, index) => (
					<Img
						key={index}
						fluid={item.image.localFile.childImageSharp.fluid}
						className={s.img}
					/>
				))}
			</div>
		</section>
	)
}

export default Gallery

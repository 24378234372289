import React from 'react'
import s from './contactform.scss'
import Remove from '../../assets/svg/remove.svg'

const ContactForm = ({ isOrderMenuOpen, setIsOrderMenuOpen }) => {
	const openClass = isOrderMenuOpen ? s.open : `closed`

	return (
		<div className={`${s.form} ${openClass}`}>
			<div className={s.close} onClick={() => setIsOrderMenuOpen(false)}>
				<Remove />
			</div>
			<p>
				Please enter your name, email, address, and order details below
				and we will get in touch with you very soon!
			</p>
			<form name='order' method='POST' data-netlify='true'>
				<input type='hidden' name='form-name' value='order' />
				<div>
					<label htmlFor='orderName'>Full Name</label>
					<input type='text' name='orderName' required />
				</div>
				<div>
					<label htmlFor='orderEmail'>Email</label>
					<input type='email' name='orderEmail' required />
				</div>
				<div>
					<label htmlFor='orderAddress'>Address</label>
					<textarea
						name='orderAddress'
						rows='6'
						cols='20'
						required
					></textarea>
				</div>
				<div>
					<label htmlFor='orderItems'>Items</label>
					<textarea
						name='orderItems'
						rows='4'
						cols='20'
						required
						placeholder='Please enter the item you want and the sizes here'
					></textarea>
				</div>

				<button type='submit'>Send</button>
			</form>
		</div>
	)
}

export default ContactForm

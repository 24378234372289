import React, { useState, useContext } from 'react'
import Img from 'gatsby-image'
import Plus from '../../assets/svg/Plus.svg'
import Minus from '../../assets/svg/Minus.svg'
import { StoreContext } from '../../context/StoreContext'
import s from './rafflecard.scss'

const RaffleCard = ({ data, id }) => {
	const [cartState, setCartState] = useContext(StoreContext)
	const [quantity, setQuantity] = useState(0)
	const isDisabled = quantity > 0 ? '' : s.disabled

	const addToCart = () => {
		const newList = cartState.cartItems
		const newItem = { id: id, name: data.title.text, tickets: quantity }

		const filtered = newList.filter((i) => {
			return i.id === id
		})

		if (filtered.length > 0) {
			const pos = newList
				.map((i) => {
					return i.id
				})
				.indexOf(id)
			newList[pos].tickets += quantity
		} else {
			newList.push(newItem)
		}

		setCartState({ ...cartState, cartItems: newList })
	}

	return (
		<div className={s.raffleCard}>
			<div className={s.raffleCard__image}>
				<Img
					fluid={data.image.localFile.childImageSharp.fluid}
					style={{ width: `100%`, height: `100%` }}
				/>
			</div>
			<div className={s.raffleCard__body}>
				<h3>{data.title.text}</h3>
				<h5>
					Donated by: <span>{data.donees.text}</span>
				</h5>
				<div
					dangerouslySetInnerHTML={{ __html: data.descrtiption.html }}
				></div>
			</div>
			<div className={s.raffleCard__meta}>
				<div className={s.quantity}>
					<span>Tickets</span>
					<div className={s.quantity__controls}>
						<div
							className={s.minus}
							onClick={() =>
								quantity > 0
									? setQuantity(quantity - 1)
									: setQuantity(quantity)
							}
						>
							<Minus />
						</div>
						<div className={s.howMany}>{quantity}</div>
						<div
							className={s.plus}
							onClick={() => setQuantity(quantity + 1)}
						>
							<Plus />
						</div>
					</div>
				</div>
				<button
					onClick={addToCart}
					className={`${s.addToCart} ${isDisabled}`}
					// data-item-id={id}
					// data-item-price='5.00'
					// data-item-url='/raffle'
					// data-item-description={data.descrtiption.text}
					// // data-item-image={data.image.localFile.publicURL}
					// data-item-name={data.title.text}
					// data-item-quantity={quantity}
				>
					Add to cart
				</button>
			</div>
		</div>
	)
}

export default RaffleCard

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { LargeHero, About, HomeEvent, Gallery, Donors } from '../slices'

const SliceZone = ({ allSlices }) => {
	return allSlices.map((s) => {
		switch (s.slice_type) {
			// These are the API IDs of the slices
			case 'home_page_hero':
				return <LargeHero key={s.id} input={s} />
			case 'about_section':
				return <About key={s.id} input={s} />
			case 'the_event_section':
				return <HomeEvent key={s.id} input={s} />
			case 'gallery':
				return <Gallery key={s.id} input={s} />
			case 'donors_and_scholarship_section':
				return <Donors key={s.id} input={s} />
			default:
				return null
		}
	})
}

SliceZone.propTypes = {
	allSlices: PropTypes.array.isRequired,
}

export default SliceZone

import { Link } from 'gatsby'
import React from 'react'
import Logo from '../../assets/svg/logo-stacked.svg'
import Cheers from '../../assets/svg/cheers.svg'

import s from './footer.scss'

const Footer = () => (
	<footer className={s.footer}>
		<div className={s.cheers}>
			<Cheers />
		</div>
		<div className={s.footer__inner}>
			<nav className={s.footerNav}>
				<Link className={s.footerNav__item} to='/#about'>
					About
				</Link>
				<Link className={s.footerNav__item} to='/#event'>
					Event
				</Link>
				<Link className={s.footerNav__item} to='/raffle'>
					Raffle
				</Link>
				<a className={s.footerNav__item} href='/'>
					Donate
				</a>
			</nav>

			<div className={s.footer__logo}>
				<Link to='/'>
					<Logo />
				</Link>
			</div>
		</div>
	</footer>
)

export default Footer

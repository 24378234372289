import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import GoodBuddies from '../../assets/svg/goodbuddies-horz.svg'
import s from './about.scss'

const ABOUT_QUERY = graphql`
	query AboutQuery {
		prismicHomePageBodyAboutSection {
			primary {
				headline {
					text
				}
				copy {
					html
				}
				cta_link {
					url
				}
				cta_text {
					text
				}
				top_image {
					localFile {
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
				bottom_image {
					localFile {
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`

const About = () => {
	const {
		prismicHomePageBodyAboutSection: {
			primary: {
				headline,
				copy,
				cta_link,
				cta_text,
				top_image,
				bottom_image,
			},
		},
	} = useStaticQuery(ABOUT_QUERY)

	return (
		<section id='about' className={s.about}>
			<GoodBuddies />
			<div className={s.about__wrapper}>
				<div className={s.about__content}>
					<span className='overline'>about</span>
					<h2>{headline.text}</h2>
					<div dangerouslySetInnerHTML={{ __html: copy.html }}></div>
					<Link to={cta_link.url} className='cta'>
						{cta_text.text}
					</Link>
				</div>
				<div className={s.about__images}>
					<Img
						fluid={bottom_image.localFile.childImageSharp.fluid}
						className={s.bottom}
					/>
					<Img
						fluid={top_image.localFile.childImageSharp.fluid}
						className={s.top}
					/>
				</div>
			</div>
		</section>
	)
}

export default About

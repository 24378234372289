/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from 'react'
import { StoreProvider } from './src/context/StoreContext'
require('./src/styles/main.global.scss')
const scrollToElement = require('scroll-to-element')

export const onRouteUpdate = ({ location }) => {
	checkHash(location)
}

const checkHash = (location) => {
	let { hash } = location
	if (hash) {
		scrollToElement(hash, {
			offset: -125,
			duration: 1000,
		})
	}
}

//Wrapping Root Element with StoreProvider
export const wrapRootElement = ({ element }) => (
	<StoreProvider>{element}</StoreProvider>
)

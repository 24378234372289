import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/SEO'
import RaffleCard from '../components/RaffleCard'
import s from '../styles/pages/pages.scss'

const RAFFLE_PAGE_QUERY = graphql`
	query RafflePageQuery {
		allPrismicRaffleItem {
			nodes {
				data {
					title {
						text
					}
					donees {
						text
					}
					image {
						localFile {
							publicURL
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
					descrtiption {
						html
						text
					}
				}
				id
			}
		}
	}
`

const RafflePage = () => {
	const {
		allPrismicRaffleItem: { nodes },
	} = useStaticQuery(RAFFLE_PAGE_QUERY)

	return (
		<>
			<SEO title='Raffle' />
			<p
				style={{
					width: `90%`,
					maxWidth: `720px`,
					margin: `48px auto`,
					textAlign: `center`,
				}}
			>
				Thank you to all our donors for contributing these wonderful
				prizes. Raffle tickets are $5/each. As you review these items,
				simply select the number of raffle tickets you'd like to submit
				per prize. Once you've made all your selections, simply click on
				your cart to check out. The raffle will close at 2 p.m. on
				October 3 and winners will be randomly picked via livestream.
				See event page for livestream information.
			</p>
			<section className={s.raffleGrid}>
				{nodes.map((node) => (
					<RaffleCard key={node.id} data={node.data} id={node.id} />
				))}
			</section>
		</>
	)
}

export default RafflePage

import { Link } from 'gatsby'
import React, { useContext } from 'react'
import { StoreContext } from '../../context/StoreContext'
import Logo from '../../assets/svg/logo-stacked.svg'
import Bag from '../../assets/svg/bag.svg'

import s from './header.scss'

const Header = () => {
	const [cartState, setCartState] = useContext(StoreContext)
	const quantity = cartState.cartItems
		.map((item) => item.tickets)
		.reduce((total, amount) => total + amount, 0)

	const openCart = () => {
		setCartState({ ...cartState, isCartOpen: !cartState.isCartOpen })
	}

	return (
		<header className={s.header}>
			<div className={`${s.header__cart} `} onClick={openCart}>
				<Bag />
				<span>{quantity}</span>
			</div>
			<div className={s.header__inner}>
				<div className={s.header__logo}>
					<Link to='/'>
						<Logo />
					</Link>
				</div>

				<nav className={s.mainNav}>
					<div
						className={`${s.mainNav__container} ${s.mainNav__containerLeft}`}
					>
						<Link className={s.mainNav__item} to='/#about'>
							About
						</Link>
						<Link className={s.mainNav__item} to='/#event'>
							Event
						</Link>
					</div>
					<div
						className={`${s.mainNav__container} ${s.mainNav__containerRight}`}
					>
						<Link className={s.mainNav__item} to='/raffle'>
							Raffle
						</Link>
						<Link className={s.mainNav__item} to='/goods'>
							Goods
						</Link>
					</div>
				</nav>
			</div>
		</header>
	)
}

export default Header

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/SEO'
import SliceZone from '../components/SliceZone'

const HOME_PAGE_QUERY = graphql`
	query HomePageQuery {
		allPrismicHomePage {
			nodes {
				uid
				data {
					body {
						... on PrismicHomePageBodyHomePageHero {
							id
							slice_type
						}
						... on PrismicHomePageBodyAboutSection {
							id
							slice_type
						}
						... on PrismicHomePageBodyTheEventSection {
							id
							slice_type
						}
						... on PrismicHomePageBodyGallery {
							id
							slice_type
						}
						... on PrismicHomePageBodyDonorsAndScholarshipSection {
							id
							slice_type
						}
					}
				}
			}
		}
	}
`

const IndexPage = () => {
	const {
		allPrismicHomePage: { nodes },
	} = useStaticQuery(HOME_PAGE_QUERY)

	return (
		<>
			<SEO title='Home' />
			<SliceZone allSlices={nodes[0].data.body} />
		</>
	)
}

export default IndexPage

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import s from './donors.scss'

const DONORS_QUERY = graphql`
	query DonorsQuery {
		prismicHomePageBodyDonorsAndScholarshipSection {
			primary {
				headline {
					text
				}
				copy {
					html
				}
				cta_link {
					url
					target
				}
				cta_text {
					text
				}
				image {
					localFile {
						childImageSharp {
							fluid {
								...GatsbyImageSharpFluid
							}
						}
					}
				}
			}
		}
	}
`

const Donors = () => {
	const {
		prismicHomePageBodyDonorsAndScholarshipSection: {
			primary: { headline, copy, cta_link, cta_text, image },
		},
	} = useStaticQuery(DONORS_QUERY)
	return (
		<section className={s.donors}>
			<div className={s.donors__wrapper}>
				<div className={s.donors__image}>
					<Img
						fluid={image.localFile.childImageSharp.fluid}
						style={{ width: `100%`, height: `100%` }}
					/>
				</div>
				<div className={s.donors__content}>
					<h2>{headline.text}</h2>
					<div dangerouslySetInnerHTML={{ __html: copy.html }}></div>
					{/* <a
						_target={cta_link.target}
						href={cta_link.url}
						className='cta'
					>
						{cta_text.text}
					</a> */}
				</div>
			</div>
		</section>
	)
}

export default Donors

import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import SEO from '../components/SEO'
import s from '../styles/pages/pages.scss'
import GoodsCard from '../components/GoodsCard'
import ContactForm from '../components/ContactForm'

const GOODS_QUERY = graphql`
	query GoodsQuery {
		allPrismicGoodsPage {
			nodes {
				data {
					intro_copy {
						text
					}
					body {
						... on PrismicGoodsPageBodyGood {
							id
							primary {
								title {
									text
								}
								price {
									text
								}
								sizes {
									text
								}
								image {
									localFile {
										childImageSharp {
											fluid {
												...GatsbyImageSharpFluid
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
`

const buttonStyles = {
	border: `none`,
	borderRadius: `4px`,
	padding: `8px 12px`,
	cursor: `pointer`,
	marginRight: `16px`,
	background: `var(--color-red)`,
	color: `var(--color-white)`,
}

const RafflePage = () => {
	const {
		allPrismicGoodsPage: {
			nodes: {
				[0]: {
					data: { intro_copy, body },
				},
			},
		},
	} = useStaticQuery(GOODS_QUERY)

	const [isOrderMenuOpen, setIsOrderMenuOpen] = useState(false)

	return (
		<>
			<SEO title='Goods' />
			<p
				style={{
					width: `90%`,
					maxWidth: `720px`,
					margin: `48px auto`,
					textAlign: `center`,
				}}
			>
				{intro_copy.text}
			</p>
			<div
				className='order'
				style={{ display: `grid`, placeItems: `center` }}
			>
				<button
					style={buttonStyles}
					onClick={() => setIsOrderMenuOpen(true)}
				>
					Place Order!
				</button>
			</div>
			<section className={s.raffleGrid}>
				{body.map((item) => (
					<GoodsCard key={item.id} data={item.primary} />
				))}
			</section>
			<ContactForm
				isOrderMenuOpen={isOrderMenuOpen}
				setIsOrderMenuOpen={setIsOrderMenuOpen}
			/>
		</>
	)
}

export default RafflePage

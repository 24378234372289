import React, { useState } from 'react'
import Img from 'gatsby-image'
import s from './goodscard.scss'

const GoodsCard = ({ data }) => {
	console.log(data)
	return (
		<div className={s.goodsCard}>
			<div className={s.goodsCard__image}>
				<Img
					fluid={data.image.localFile.childImageSharp.fluid}
					style={{ width: `100%`, height: `100%` }}
				/>
			</div>
			<div className={s.goodsCard__body}>
				<h3>{data.title.text}</h3>
				<h4>
					<span>${data.price.text}</span>
				</h4>
				<h5>
					Sizes: <span>{data.sizes.text}</span>
				</h5>
			</div>
		</div>
	)
}

export default GoodsCard

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GB from '../../assets/svg/gb-solo.svg'
import s from './event.scss'

const HOME_EVENT_QUERY = graphql`
	query EventQuery {
		prismicHomePageBodyTheEventSection {
			items {
				date {
					text
				}
				date_item {
					html
				}
			}
			primary {
				copy {
					html
				}
				headline {
					text
				}
			}
		}
	}
`

const HomeEvent = () => {
	const {
		prismicHomePageBodyTheEventSection: {
			items,
			primary: { copy, headline },
		},
	} = useStaticQuery(HOME_EVENT_QUERY)

	return (
		<section id='event' className={s.event}>
			<GB />

			<div className={s.event__wrapper}>
				<div className={s.event__content}>
					<span className='overline'>the event</span>
					<h2>{headline.text}</h2>
					<div dangerouslySetInnerHTML={{ __html: copy.html }}></div>
				</div>
				<div className={s.event__dates}>
					{items.map((item, index) => (
						<div className={s.date} key={index}>
							<div className={s.date__day}>{item.date.text}</div>
							<div
								className={s.date__description}
								dangerouslySetInnerHTML={{
									__html: item.date_item.html,
								}}
							></div>
						</div>
					))}
				</div>
			</div>
		</section>
	)
}

export default HomeEvent

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import s from './largehero.scss'

const LARGE_HERO_QUERY = graphql`
	query LaregHeroQuery {
		prismicHomePageBodyHomePageHero {
			primary {
				headline {
					html
				}
				svg {
					url
					localFile {
						publicURL
					}
				}
			}
		}
	}
`

const LargeHero = () => {
	const {
		prismicHomePageBodyHomePageHero: {
			primary: { headline, svg },
		},
	} = useStaticQuery(LARGE_HERO_QUERY)

	return (
		<section className={s.largeHero}>
			<div className={s.largeHero__wrapper}>
				<div className={`${s.largeHero__half} ${s.img}`}>
					<img src={svg.localFile.publicURL} alt='' />
				</div>
				<div
					className={`${s.largeHero__half} ${s.headline}`}
					dangerouslySetInnerHTML={{ __html: headline.html }}
				></div>
			</div>
		</section>
	)
}

export default LargeHero

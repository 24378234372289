import React, { createContext, useState } from 'react'

const initialState = {
	isCartOpen: false,
	cartItems: [],
}

export const StoreContext = createContext()

export const StoreProvider = ({ children }) => {
	const [cartState, setCartState] = useState(initialState)
	return (
		<StoreContext.Provider value={[cartState, setCartState]}>
			{children}
		</StoreContext.Provider>
	)
}
